'use client'

import { useEffect, useRef } from 'react'
import type { SwiperContainer } from 'swiper/element'
import { register } from 'swiper/element'

import { ISlide } from '@/src/entities/slider/model'
import { useAspectRatio } from '@/src/shared/hooks/useAspectRatio'

import {
  mainSlideAspectRatio,
  swiperParams,
  swiperThumbsParams,
  thumbnailsAspectRatio,
} from '../config'
import { SliderMain } from './SliderMain'
import { SliderThumbnails } from './SliderThumbnails'

export type ISliderSwiper = {
  slides: ISlide[]
}

export default function SliderSwiper({ slides }: ISliderSwiper) {
  const { id, styles } = useAspectRatio(mainSlideAspectRatio)
  const swiperElRef = useRef<SwiperContainer>(null)
  const swiperThumbRef = useRef<SwiperContainer>(null)

  useEffect(() => {
    register()

    Object.assign(swiperElRef.current ?? {}, swiperParams)
    Object.assign(swiperThumbRef.current ?? {}, swiperThumbsParams)

    swiperElRef.current?.initialize()
    swiperThumbRef.current?.initialize()
  }, [])

  return (
    <>
      {styles}

      <div data-id={id} className="slider__main">
        <SliderMain aspectRatio={mainSlideAspectRatio} ref={swiperElRef} slides={slides} />
      </div>

      <div className="slider__thumbnails">
        <SliderThumbnails
          aspectRatio={thumbnailsAspectRatio}
          ref={swiperThumbRef}
          slides={slides}
        />
      </div>
    </>
  )
}
