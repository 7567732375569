'use client'

import './VideoAndImage.scss'

import classNames from 'classnames'
import { useMemo, useRef } from 'react'

import { NoSSSR } from '@/src/shared/ui/NoSSSR'

import { useAspectRatio } from '../../hooks/useAspectRatio'
import useHover from '../../hooks/useHover'
import isTouchEnabled from '../../lib/isTouch'
import { IAspectRatio } from '../../model/aspectRatio'
import { Image } from '../Image'
import { IVideoImperativeHandlers, Video } from '../Video'

export type IVideoAndImage = {
  image?: string | null
  imageMobile?: string | null
  video?: string | null
  videoMobile?: string | null
  sizes?: string
  priority?: boolean
  alt: string | null
  aspectRatio: IAspectRatio
}

const VideoAndImage = ({ aspectRatio, ...rest }: IVideoAndImage) => {
  const { styles, id } = useAspectRatio(aspectRatio)

  return (
    <>
      {styles}
      <div data-id={id} className="video-and-image-wrapper">
        <NoSSSR>
          <VideoAndImageInner aspectRatio={aspectRatio} {...rest} />
        </NoSSSR>
      </div>
    </>
  )
}

const isTouch = isTouchEnabled()

const VideoAndImageInner = ({
  image,
  imageMobile,
  video,
  videoMobile,
  alt,
  sizes,
  priority = false,
  aspectRatio,
}: IVideoAndImage) => {
  const hasImage = image || imageMobile
  const hasVideo = video || videoMobile
  const hasOnlyImage = hasImage && !hasVideo
  const hasOnlyVideo = hasVideo && !hasImage
  const hasVideoWithImage = hasVideo && hasImage
  const isNothing = !hasImage && !hasVideo

  const { styles, id } = useAspectRatio(aspectRatio)
  const hoverRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<IVideoImperativeHandlers>(null)
  const isHovering = useHover(hoverRef)

  useMemo(() => {
    const video = videoRef.current

    if (!hasVideoWithImage || isTouch) return

    if (isHovering) {
      video?.play()
    } else {
      video?.pause()
    }
  }, [isHovering, hasVideoWithImage])

  const renderImage = (
    <Image
      priority={priority}
      aspectRatio={aspectRatio}
      src={image}
      srcMobile={imageMobile}
      alt={alt}
      sizes={sizes}
      fillParent
    />
  )

  if (hasOnlyImage || isNothing) return renderImage

  const videoProps = {
    ref: videoRef,
    priority,
    aspectRatio,
    src: video as string,
    srcMobile: videoMobile,
    sizes,
  }

  if (hasOnlyVideo) return <Video {...videoProps} />

  return (
    <>
      {styles}

      <div
        ref={hoverRef}
        className={classNames('video-and-image', {
          'video-and-image--hovered': isHovering,
          'video-and-image--isTouch': isTouch,
        })}
      >
        <div data-id={id} className="video-and-image__inner">
          <div className="video-and-image__background">
            <Video {...videoProps} autoplay={isTouch} />
          </div>
          {!isTouch && <div className="video-and-image__foreground">{renderImage}</div>}
        </div>
      </div>
    </>
  )
}

export default VideoAndImage
