import { CSSProperties, forwardRef } from 'react'
// import { useWindowSize } from 'react-use'
import { SwiperContainer } from 'swiper/element'

import { ISlide } from '@/src/entities/slider/model'
import { BreakpointsMd } from '@/src/shared/config/variables'
import { IAspectRatio } from '@/src/shared/model'
import { VideoOrImage } from '@/src/shared/ui/VideoOrImage'

type IProps = {
  aspectRatio: IAspectRatio
  slides: ISlide[]
}

export const SliderThumbnails = forwardRef<SwiperContainer, IProps>(
  ({ slides, aspectRatio }, ref) => {
    return (
      <swiper-container
        // @ts-ignore
        class="thumbs-swiper"
        style={{} as CSSProperties}
        ref={ref}
        init={false}
      >
        {slides.map((slide) => {
          const { id, image, video } = slide

          return (
            <swiper-slide key={slide.id}>
              <div className="slider__thumb">
                <div className="slider__thumb-inner">
                  <VideoOrImage
                    alt={`slide-thumb-${id}`}
                    image={image}
                    video={video}
                    // priority={key < 4}
                    sizes={`(max-width: ${BreakpointsMd}) 50vw, 25vw`}
                    aspectRatio={aspectRatio}
                  />
                </div>
              </div>
            </swiper-slide>
          )
        })}
      </swiper-container>
    )
  },
)

SliderThumbnails.displayName = 'SliderThumbnails'
