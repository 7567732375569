import { HOST } from '../../app/config/env'

export const formatLink = (url: string | null) => {
  const origin = url ? new URL(url).origin : ''
  const isSite = origin === HOST
  const parsedUrl = url ? new URL(url).pathname : ''

  return {
    internalLink: isSite,
    href: isSite ? parsedUrl : url || '',
  }
}
