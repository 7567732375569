import './ProductCard.scss'

import cn from 'classnames'
import { useFormatter } from 'next-intl'

import { BreakpointsSm } from '@/src/shared/config/variables'
import { Image } from '@/src/shared/ui/Image'
import { LocalizedLink } from '@/src/shared/ui/LocalizedLink'

import { IProduct } from '../../model'

export type IProductCard = {
  className?: string
  product?: IProduct
  loading?: boolean
  size?: 'm' | 'l' | 'xs'
  priority?: boolean
}

const imageSizes = {
  xs: {
    width: 175,
    height: 225,
    sm: {
      width: 175,
      height: 225,
    },
  },
  m: {
    width: 834,
    height: 510,
    sm: {
      width: 834,
      height: 510,
    },
  },
  l: {
    width: 834,
    height: 1020,
    sm: {
      width: 834,
      height: 1020,
    },
  },
}

const ProductCard = ({
  className,
  product,
  loading = false,
  size = 'l',
  priority = false,
}: IProductCard) => {
  const format = useFormatter()

  if (loading || !product) {
    return (
      <ProductCard
        className="product-card--loading"
        size={size}
        product={{
          id: 0,
          slug: '',
          image: null,
          imageHorizontal: null,
          price: 35.0,
          title: '4tuna for life tee',
        }}
      />
    )
  }

  const { title, slug, price, image, imageHorizontal } = product

  const isSizeXS = size === 'xs'
  const isSizeM = size === 'm'

  const src = isSizeM ? imageHorizontal : image
  const sizes = isSizeXS
    ? `(max-width: ${BreakpointsSm}) 50vw, 50vw`
    : `(max-width: ${BreakpointsSm}) 100vw, 50vw`

  const Wrapper = slug ? LocalizedLink : 'article'

  return (
    <Wrapper
      href={`/store/${slug}`}
      className={cn('product-card', `product-card--size-${size}`, {
        [`${className}`]: !!className,
      })}
    >
      <div className="product-card__inner">
        <div className="product-card__media">
          <Image
            priority={priority}
            sizes={sizes}
            fillParent
            aspectRatio={imageSizes[size]}
            src={src}
            alt={title}
          />
        </div>

        <div className="product-card__text-box">
          <h2 className="product-card__title">{title}</h2>

          {price && <div className="product-card__price">{format.number(price, 'currency')}</div>}
        </div>
      </div>
    </Wrapper>
  )
}

export default ProductCard
