'use client'

import { Autoplay, Thumbs } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'

import { isDevEnv } from '@/src/app/config/env'
import { isLightHouse } from '@/src/shared/lib/detectDevice'

export const disableAutoplay = isDevEnv || isLightHouse

export const swiperParams: SwiperOptions = {
  slidesPerView: 1,
  spaceBetween: 10,
  modules: [Thumbs, Autoplay],
  speed: 500,
  watchSlidesProgress: true,
  autoplay: disableAutoplay
    ? false
    : {
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
        waitForTransition: false,
      },
  on: {
    autoplayTimeLeft(_, __, progress) {
      const percentageProgress = ((1 - progress) * 100).toFixed(2)
      document.documentElement.style.setProperty('--slider-progress', `${percentageProgress}%`)
    },

    slideChange(swiper) {
      const slide = swiper?.slides[swiper?.activeIndex]
      const video = slide?.querySelector('video')

      if (video) video.currentTime = 0
    },
  },

  thumbs: {
    swiper: '.thumbs-swiper',
  },
}

export const swiperThumbsParams: SwiperOptions = {
  spaceBetween: 0,
  slidesPerView: 4,
  modules: [Thumbs],
  freeMode: true,
  watchSlidesProgress: true,
}

export const mainSlideAspectRatio = {
  width: 1728,
  height: 769,

  sm: {
    width: 449,
    height: 574,
  },
}

export const thumbnailsAspectRatio = {
  width: 407,
  height: 76,

  sm: {
    width: 407,
    height: 76,
  },
}
