import classNames from 'classnames'
import Link from 'next/link'
import { CSSProperties, forwardRef, useRef } from 'react'
import { SwiperContainer } from 'swiper/element'

import { ISlide } from '@/src/entities/slider/model'
import { formatLink } from '@/src/shared/lib/formatLink'
import { IAspectRatio } from '@/src/shared/model'
import { IVideoImperativeHandlers } from '@/src/shared/ui/Video'
import { VideoOrImage } from '@/src/shared/ui/VideoOrImage'

type IProps = {
  slides: ISlide[]
  aspectRatio: IAspectRatio
}

export const SliderMain = forwardRef<SwiperContainer, IProps>(({ slides, aspectRatio }, ref) => {
  return (
    <swiper-container
      // @ts-ignore
      class="main-swiper"
      style={{} as CSSProperties}
      ref={ref}
      init={false}
    >
      {slides.map((slide, key) => (
        <SwiperSlide idKey={key} slide={slide} key={key} aspectRatio={aspectRatio} />
      ))}
    </swiper-container>
  )
})

type ISwiperSlide = {
  idKey: number
  slide: ISlide
  aspectRatio: IAspectRatio
}

const SwiperSlide = ({ slide, aspectRatio, idKey }: ISwiperSlide) => {
  const ref = useRef<IVideoImperativeHandlers>(null)

  const { id, image, url, video, videoMobile, imageMobile, videoDuration } = slide

  const { href, internalLink } = formatLink(url)
  const delay = videoDuration === null ? 2000 : videoDuration * 1000
  const Wrapper = href ? Link : 'article'

  return (
    <swiper-slide data-swiper-autoplay={delay}>
      <Wrapper
        className={classNames('slider__link', {
          'slider__link--has-link': !!href,
        })}
        href={href}
        target={internalLink ? '_self' : '_blank'}
      >
        <VideoOrImage
          ref={ref}
          aspectRatio={aspectRatio}
          sizes="90vw"
          priority={idKey === 0}
          video={video}
          videoMobile={videoMobile}
          image={image}
          imageMobile={imageMobile}
          alt={`slide-${id}`}
        />
      </Wrapper>
    </swiper-slide>
  )
}

SliderMain.displayName = 'SliderMain'
