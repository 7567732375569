import './VideoOrImage.scss'

import { forwardRef } from 'react'

import { IAspectRatio } from '@/src/shared/model'
import { Image } from '@/src/shared/ui/Image'
import { Video } from '@/src/shared/ui/Video'

import { IVideoImperativeHandlers } from '../Video'

export type IVideoOrImage = {
  image?: string | null
  imageMobile?: string | null
  video?: string | null
  videoMobile?: string | null
  sizes?: string
  priority?: boolean
  alt: string | null
  onVideoLoaded?: (id: string) => void

  aspectRatio: IAspectRatio
}

const VideoOrImage = forwardRef<IVideoImperativeHandlers, IVideoOrImage>(
  (
    {
      image,
      imageMobile,
      video,
      videoMobile,
      alt,
      sizes,
      priority = false,
      aspectRatio,
      onVideoLoaded,
    },
    ref,
  ) => {
    if (video)
      return (
        <Video
          ref={ref}
          onLoaded={onVideoLoaded}
          priority={priority}
          aspectRatio={aspectRatio}
          src={video}
          srcMobile={videoMobile}
          poster={image}
          posterMobile={imageMobile}
          sizes={sizes}
        />
      )

    return (
      <Image
        priority={priority}
        aspectRatio={aspectRatio}
        src={image}
        srcMobile={imageMobile}
        alt={alt}
        sizes={sizes}
        fillParent
      />
    )
  },
)

VideoOrImage.displayName = 'VideoOrImage'

export default VideoOrImage
