import { isServer } from './isServerOrClient'

function isTouchEnabled() {
  if (isServer) return false

  // @ts-ignore: Unreachable code error
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator?.msMaxTouchPoints > 0
}

export default isTouchEnabled
