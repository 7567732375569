import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/page.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/entities/game/ui/GameCard/GameCard.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/entities/highlight/ui/HighlightCard/HighlightCard.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/entities/product/ui/ProductCard/ProductCard.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/_ui/MainPage/MainPage.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/shared/ui/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/shared/ui/Image/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/shared/ui/Video/Video.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/VideoOrImage/VideoOrImage.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/HighlightsCarousel/ui/HighlightsCarousel.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/widgets/HighlightsCarousel/ui/HighlightsCarouselSwiper.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/ProductsCarousel/ui/ProductsCarousel.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/Products/ui/ProductsGrid.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/Title/Title.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/widgets/ProductsCarousel/ui/ProductsCarouselSwiper.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/Slider/ui/Slider.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/widgets/Slider/ui/SliderSwiper.tsx");
