'use client'

import { CSSProperties, useEffect, useRef } from 'react'
import type { SwiperContainer } from 'swiper/element'
import { register } from 'swiper/element'
import { Pagination } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'

import { swiperStyles } from '@/src/app/scss/vendors/swiper.css'
import { IHighlight } from '@/src/entities/highlight/model'
import { HighlightCard } from '@/src/entities/highlight/ui/HighlightCard'
import { ColorBasePurple500, ColorBasePurple900 } from '@/src/shared/config/variables'

export type IHighlightsCarouselSwiper = {
  highlights: IHighlight[]
}

const swiperParams: SwiperOptions = {
  injectStyles: [
    swiperStyles,
    ` .swiper {
        overflow: visible;
      }
    `,
  ],
  modules: [Pagination],
  slidesPerView: 1,
  spaceBetween: 0,
  pagination: { clickable: true },
  breakpoints: {
    480: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
  },
}

export default function HighlightsCarouselSwiper({ highlights }: IHighlightsCarouselSwiper) {
  const swiperElRef = useRef<SwiperContainer>(null)

  useEffect(() => {
    register()

    Object.assign(swiperElRef.current ?? {}, swiperParams)

    swiperElRef.current?.initialize()
  }, [])

  return (
    <>
      <swiper-container
        style={
          {
            '--swiper-pagination-bullet-horizontal-gap': '6px',
            '--swiper-pagination-bullet-inactive-opacity': 1,
            '--swiper-pagination-bullet-inactive-color': ColorBasePurple900,
            '--swiper-pagination-bullet-width': '12px',
            '--swiper-pagination-bullet-height': '12px',
            '--swiper-pagination-bottom': '-40px',
            '--swiper-pagination-color': ColorBasePurple500,
          } as CSSProperties
        }
        ref={swiperElRef}
        init={false}
      >
        {highlights.map((highlight) => (
          <swiper-slide key={highlight.id}>
            <HighlightCard highlight={highlight} />
          </swiper-slide>
        ))}
      </swiper-container>
    </>
  )
}
